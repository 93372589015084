import { MantisSignIn } from '../../components/MantisSignIn'
import { MantisSignOut } from '../../components/MantisSignOut'
import { MantisCard } from '../../components/MantisCard'
import { AppBackground, TopNav, SideNav, MainContainer, TopNavLeftContainer, TopNavRightContainer, ButtonContainer, StyledButton, MantisStyledCardContainer, StyledAnchorContainer, StyledAnchor } from "../../components/Styled/mantis"


const MantisDashboard = ({ session, setSession }) => {
    if (!session.active) return <AppBackground><MantisSignIn session={session} setSession={setSession} /></AppBackground>
    return (
        <AppBackground>
            <TopNav>
                <TopNavLeftContainer>
                    <ButtonContainer>
                        <StyledButton><StyledAnchor href="/">Project Mantis</StyledAnchor></StyledButton>
                    </ButtonContainer>
                </TopNavLeftContainer>
                <TopNavRightContainer>
                    <MantisSignOut setSession={setSession}/>
                </TopNavRightContainer>
            </TopNav>
            <SideNav>
                <StyledAnchorContainer>
                    <StyledAnchor href={`/mantis/endpoints`}>Endpoints</StyledAnchor>
                </StyledAnchorContainer>
                <StyledAnchorContainer position="absolute" bottom="40px">
                    <StyledAnchor href={`/quantum`}>Quantum</StyledAnchor>
                </StyledAnchorContainer>
                <StyledAnchorContainer position="absolute" bottom="8px">
                    <StyledAnchor href={`/mantis`}>Mantis</StyledAnchor>
                </StyledAnchorContainer>
            </SideNav>
            <MainContainer>
                <MantisStyledCardContainer>
                    <StyledAnchor href="/mantis/endpoints">
                        <MantisCard>
                            <div>Endpoints</div>
                        </MantisCard>
                    </StyledAnchor>
                </MantisStyledCardContainer>
            </MainContainer>
        </AppBackground>
    )
}

export default MantisDashboard