import { useEffect, useState } from 'react'


import { useMantis } from '../../hooks/mantis/mantis.hooks'
import { MantisTable } from '../../components/MantisTable'


const QuantumMantis = ({key, config}) => {
    const { isLoading, isError, data} = useMantis(config)
    const [appData, setAppData] = useState(null)

    useEffect(() => {
        if (!isLoading) {
            setAppData(data.data)
        }
    }, [data])

    
    if (isLoading) {
        return (<div />)
    }

    if (!appData) {
        return (<div />)
    }
    
    
    if (appData.code === 400) {
        return (<div />)
    }
    
    return (<MantisTable key={key} data={appData} />)
}

export default QuantumMantis