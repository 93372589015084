import { useRef, useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom';

import { useToasts } from 'react-toast-notifications'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCode, faLightbulb, faTerminal, faLaptopCode, faArrowTrendUp } from '@fortawesome/free-solid-svg-icons'

import { useAngelCodeMutation } from '../../hooks/angelcode/angelcode.hooks'

import { AppBackground, BackgroundContainer, InlineText, Description, TextAreaContainer, StyledTextArea, TopNav, InputContainer, MantisContainer, StyledInput, ButtonContainer, StyledButton, StyledAnchor, GridContainer, MainContainer, MantisSection, GridComponent, CenterComponent, MantisTitle, StyledImage } from "../../components/Styled/mantis"


const AngelCode = ({ session, setSession }) => {
    const location = useLocation();
    const contactRef = useRef()
    const topRef = useRef()
    const { angelCodeAPI } = useAngelCodeMutation()
    const [activeComponent, setActiveComponent] = useState('AngelCode')
    const [width, setWindowWidth] = useState(window.innerWidth);
    

    const [scrollActive, setScrollActive] = useState(false)

    const onComponentClick = (comp) => {
        setActiveComponent(comp)
    }

    const handleScroll = (event) => {
        if (event.target.scrollTop > 440) {
            setScrollActive(true)
        } else {
            setScrollActive(false)
        }
    }

    useEffect(() => { 

        updateDimensions();

        window.addEventListener("resize", updateDimensions);
        return () => 
            window.removeEventListener("resize",updateDimensions);
    }, [])

    const updateDimensions = () => {
        const width = window.innerWidth
        setWindowWidth(width)
    }

    const Startup = () => {
        const { addToast } = useToasts()
    // const { mantisSignIn } = useMantisSignInMutation()
        const [payload, setPayload] = useState({})

        const [emailAddress, setEmailAddress] = useState('')
        const [companyName, setCompanyName] = useState('')
        

        const onClickStartupSignup = async() => {
            let warning = false;

            if (!emailAddress) {
                warning = true
                addToast('Email Address Empty', { appearance: 'warning' })
            }

            // TODO: Password & Email Validation Logic
            if (!emailAddress.includes('.') || !emailAddress.includes('@')) {
                warning = true
                addToast('Invalid Email Address', { appearance: 'warning' })
            }

            if (warning) return;
            
            payload.type = 'company'
            const resp = await angelCodeAPI({operation: 'post', data: payload})
            if (resp.data.code === 200 || resp.data.code === 201) {
                // Reset Payload
                setPayload({})
                setEmailAddress('')
                setCompanyName('')
                addToast('Startup Account Created! You will receive a follow-up email from the AngelCode team shortly.', { appearance: 'success' })
            }  else {
                addToast(JSON.stringify(resp.data.data), { appearance: 'error' })
            }
        }

        const onBlurSignup = (key, event) => {
            setPayload(payload => ({...payload, [key]: event.target.value}))
        }
        
        const onChangeEmail = (event) => {
            event.target.value = event.target.value.toLowerCase()
            setEmailAddress(event.target.value)
        }

        const onChangeCompanyName = (event) => {
            setCompanyName(event.target.value)
        }
        return (
            <GridContainer gridGap='0'>
                <GridComponent background="#fff">
                    <CenterComponent>
                        <div>
                            <FontAwesomeIcon icon={faLightbulb} fontSize={'40px'} />
                            <MantisTitle fontSize="30px">Startups</MantisTitle>
                            <Description width="60%" fontSize={`${width > 768 ? '16px' : '18px'}`}>Have a product idea and want to start the journey to bring it to life?</Description>
                            <Description padding="20px 0px 0px 0px" width="80%" fontSize={`${width > 768 ? '11px' : '13px'}`}>Signup now to unlock our network of Angel Developers and <InlineText color="rgba(220,107,182,1)">AngelCode</InlineText> resources.</Description>
                        </div>
                    </CenterComponent>
                </GridComponent>
                <GridComponent background="rgba(186,140,171,1)">
                    <CenterComponent>
                        <div>
                            <MantisTitle color='#fff' padding="12px 0px" fontSize="22px">Startup Signup</MantisTitle>
                            <InputContainer width={`${width > 768 ? '70%' : '80%'}`}>
                                <StyledInput 
                                    type="text" 
                                    placeholder="Company Name" 
                                    onBlur={(e) => onBlurSignup('name', e)}
                                    onChange={(e) => onChangeCompanyName(e)}
                                    value={companyName}
                                    background="#fff"
                                />
                            </InputContainer>
                            <InputContainer width={`${width > 768 ? '70%' : '80%'}`}> 
                                <StyledInput 
                                    type="email" 
                                    placeholder="Email Address" 
                                    onBlur={(e) => onBlurSignup('email_address', e)}
                                    onChange={(e) => onChangeEmail(e)}
                                    value={emailAddress}
                                    background="#fff"
                                />
                            </InputContainer>
                            <ButtonContainer>
                                <StyledButton onClick={onClickStartupSignup}>Signup</StyledButton>
                            </ButtonContainer>
                        </div>
                    </CenterComponent>
                </GridComponent>
            </GridContainer>
        )
    }

    

    const Developers = () => {
        const { addToast } = useToasts()
    // const { mantisSignIn } = useMantisSignInMutation()
        const [payload, setPayload] = useState({})
        const [emailAddress, setEmailAddress] = useState('')
        const [developerName, setDeveloperName] = useState('')
        

        const onClickDeveloperSignup = async() => {
            let warning = false;

            if (!emailAddress) {
                warning = true
                addToast('Email Address Empty', { appearance: 'warning' })
            }

            // TODO: Password & Email Validation Logic
            if (!emailAddress.includes('.') || !emailAddress.includes('@')) {
                warning = true
                addToast('Invalid Email Address', { appearance: 'warning' })
            }

            if (warning) return;

            payload.type = 'developer'
            const resp = await angelCodeAPI({operation: 'post', data: payload})
            if (resp.data.code === 200 || resp.data.code === 201) {
                // Reset Payload
                setPayload({})
                setEmailAddress('')
                setDeveloperName('')
                addToast('Developer Account Created! You will receive a follow-up email from the AngelCode team shortly.', { appearance: 'success' })
            }  else {
                addToast(JSON.stringify(resp.data.data), { appearance: 'error' })
            }
        }

        const onBlurSignup = (key, event) => {
            setPayload(payload => ({...payload, [key]: event.target.value}))
        }
        

        const onChangeEmail = (event) => {
            event.target.value = event.target.value.toLowerCase()
            setEmailAddress(event.target.value)
        }

        const onChangeDeveloperName = (event) => {
            setDeveloperName(event.target.value)
        }
        return (
            <GridContainer gridGap='0' height={`${width > 768 ? '500px' : '800px'}`}>
                <GridComponent height={`${width > 768 ? '500px' : '400px'}`}>
                    <CenterComponent height={`${width > 768 ? '500px' : '400px'}`} position={`${width > 768 ? 'absolute' : 'relative'}`}>
                        <div>
                            <FontAwesomeIcon icon={faLaptopCode} fontSize={'40px'} color="#fff" />
                            <MantisTitle color="#fff" fontSize="30px">Angel Developer</MantisTitle>
                            <Description width={`${width > 768 ? '65%' : '80%'}`} color="#fff" fontSize={`${width > 768 ? '16px' : '20px'}`} lineHeight="22px">Are you an elite developer with multiple projects under your belt and have the track record to prove it?</Description>
                            <Description padding="20px 0px 0px 0px" width={`${width > 768 ? '40%' : '80%'}`} color="#fff" fontSize={`${width > 768 ? '11px' : '16px'}`}><InlineText color="rgba(220,107,182,1)">AngelCode</InlineText> is opening up a limited number of seats in the first round of Angel Developers.</Description>
                            <Description padding="20px 0px 0px 0px" width={`${width > 768 ? '40%' : '80%'}`} color="#fff" fontSize={`${width > 768 ? '11px' : '16px'}`}>Signup now to be the first on the list.</Description>
                        </div>
                    </CenterComponent>
                </GridComponent>
                <GridComponent background={`${width > 768 ? 'url(img/developer-bg.png) repeat center center fixed' : 'url(img/developer-bg.png) repeat center center'}`} height={`${width > 768 ? '500px' : '400px'}`}>
                    {/* <BackgroundContainer height={`${width > 768 ? '500px' : '600px'}`} filter="blur(2px)" background="url(img/bg.png) no-repeat center center fixed" transform='scale(1.00)'/> */}
                    <CenterComponent height={`${width > 768 ? '500px' : '400px'}`} position={`${width > 768 ? 'absolute' : 'relative'}`}>
                        <div>
                            <MantisTitle color="#fff" padding="12px 0px" fontSize="22px">Become an Angel Developer</MantisTitle>
                            <InputContainer width={`${width > 768 ? '70%' : '80%'}`}>
                                <StyledInput 
                                    type="text" 
                                    placeholder="Name" 
                                    onBlur={(e) => onBlurSignup('name', e)}
                                    onChange={(e) => onChangeDeveloperName(e)}
                                    value={developerName}
                                    background="#292C31"
                                    color="#fff"
                                />
                            </InputContainer>
                            <InputContainer width={`${width > 768 ? '70%' : '80%'}`}> 
                                <StyledInput 
                                    type="email" 
                                    placeholder="Email Address" 
                                    onBlur={(e) => onBlurSignup('email_address', e)}
                                    onChange={(e) => onChangeEmail(e)}
                                    value={emailAddress}
                                    background="#292C31"
                                    color="#fff"
                                />
                            </InputContainer>
                            <ButtonContainer>
                                <StyledButton onClick={onClickDeveloperSignup}>Signup</StyledButton>
                            </ButtonContainer>
                        </div>
                    </CenterComponent>
                </GridComponent>
            </GridContainer>
        )
    }

    const Contact = () => {
        const { addToast } = useToasts()
        const [payload, setPayload] = useState({})

        const [contactEmailAddress, setContactEmailAddress] = useState('')
        const [contactName, setContactName] = useState('')
        const [contactMessage, setContactMessage] = useState('')
        

        const onClickContact = async() => {
            let warning = false;

            if (!contactEmailAddress) {
                warning = true
                addToast('Email Address Empty', { appearance: 'warning' })
            }

            if (!contactMessage) {
                warning = true
                addToast('No Message', { appearance: 'warning' })
            }

            // TODO: Password & Email Validation Logic
            if (!contactEmailAddress.includes('.') || !contactEmailAddress.includes('@')) {
                warning = true
                addToast('Invalid Email Address', { appearance: 'warning' })
            }

            if (warning) return;

            const resp = await angelCodeAPI({operation: 'post', data: payload, endpoint: 'emails'})
            if (resp.data.code === 200 || resp.data.code === 201) {
                // Reset Payload
                setPayload({})
                setContactEmailAddress('')
                setContactName('')
                setContactMessage('')
                addToast('Message Sent!', { appearance: 'success' })
            }  else {
                addToast(JSON.stringify(resp.data.data), { appearance: 'error' })
            }
        }

        const onBlurSendMessage = (key, event) => {
            setPayload(payload => ({...payload, [key]: event.target.value}))
        }
        

        const onChangeEmail = (event) => {
            event.target.value = event.target.value.toLowerCase()
            setContactEmailAddress(event.target.value)
        }

        const onChangeContactName = (event) => {
            setContactName(event.target.value)
        }

        const onChangeContactMessage = (event) => {
            setContactMessage(event.target.value)
        }
        return (
            <GridContainer ref={contactRef} gridGap='0' height={`${width > 768 ? '500px' : '600px'}`}>
                <GridComponent background="#292C31" color="rgba(232, 232, 233, 1)">
                    <CenterComponent height={`${width > 768 ? '500px' : '600px'}`} position={`${width > 768 ? 'absolute' : 'relative'}`}>
                        <div>
                            <MantisTitle padding="12px 0px" fontSize={`${width > 768 ? '22px' : '24px'}`}>Contact Us</MantisTitle>
                            <Description color="rgba(232, 232, 233, 1)" padding="0" width={`${width > 768 ? '50%' : '80%'}`} fontSize={`${width > 768 ? '12px' : '16px'}`}>Have questions or would like to learn more what <InlineText color="rgba(220,107,182,1)">AngelCode</InlineText> can do for you.</Description>
                            <Description color="rgba(232, 232, 233, 1)" padding="12px 0px" width={`${width > 768 ? '50%' : '80%'}`} fontSize={`${width > 768 ? '12px' : '16px'}`}>Shoot us an email below and we will be in touch.</Description>
                            <InputContainer width={`${width > 768 ? '70%' : '80%'}`}>
                                <StyledInput 
                                    type="text" 
                                    color='rgba(232, 232, 233, 1)'
                                    placeholder="Name" 
                                    onBlur={(e) => onBlurSendMessage('name', e)}
                                    onChange={(e) => onChangeContactName(e)}
                                    value={contactName}
                                />
                            </InputContainer>
                            <InputContainer width={`${width > 768 ? '70%' : '80%'}`}> 
                                <StyledInput 
                                    type="email" 
                                    color='rgba(232, 232, 233, 1)'
                                    placeholder="Email Address" 
                                    onBlur={(e) => onBlurSendMessage('email_address', e)}
                                    onChange={(e) => onChangeEmail(e)}
                                    value={contactEmailAddress}
                                />
                            </InputContainer>
                            <TextAreaContainer width={`${width > 768 ? '70%' : '80%'}`}>
                                <StyledTextArea 
                                    background="rgba(232, 232, 233, 1)" 
                                    rows="5" 
                                    placeholder='Message'
                                    onBlur={(e) => onBlurSendMessage('message', e)}
                                    onChange={(e) => onChangeContactMessage(e)}
                                    value={contactMessage}
                                />
                            </TextAreaContainer>
                            <ButtonContainer>
                                <StyledButton onClick={onClickContact}>Send</StyledButton>
                            </ButtonContainer>
                        </div>
                    </CenterComponent>
                </GridComponent>
            </GridContainer>
        )
    }
    
    const onClickContactLink = () => {
        contactRef.current.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
        });
    }

    const onClickLogo= () => {
        topRef.current.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
        });
    }

    const Footer = () => {
        return (
            <GridContainer gridGap='0' height="120px">
                <GridComponent background="#292C31" color="rgba(232, 232, 233, 1)"/>
                <GridComponent background="#292C31" color="rgba(232, 232, 233, 1)">
                    <CenterComponent height="120px" position={`${width > 768 ? 'absolute' : 'relative'}`}>
                        <div>
                            <Description padding="0" color="rgba(232, 232, 233, 1)">
                            <InlineText padding="0px 4px"><a href="https://www.instagram.com/angelcode.co/" rel="noreferrer" target="_blank"><StyledImage src="/img/instagramIcon.png" height="40px" /></a></InlineText>
                            <InlineText padding="0px 4px"><a href="https://twitter.com/AngelCode_co" rel="noreferrer" target="_blank"><StyledImage src="/img/twitterIcon.png" height="40px" /></a></InlineText>
                            </Description>
                            <Description padding="0"><InlineText color="rgba(232, 232, 233, 1)">angelcode@projectmantis.com</InlineText></Description>
                            <Description padding="0"><InlineText color="rgba(232, 232, 233, 1)" fontSize="10px">© 2022 AngelCode</InlineText></Description>
                        </div>
                    </CenterComponent>
                </GridComponent>
                <GridComponent background="#292C31" color="rgba(232, 232, 233, 1)"/>
            </GridContainer>
        )
    }
    
    
    return (
        <AppBackground>
            <MainContainer height="100vh" top="0" left="0" width="100%" onScroll={(event) => handleScroll(event)}>
                <MantisSection ref={topRef} height={`${width > 768 ? '500px' : '500px'}`} background={`${width > 768 ? 'url(img/hero-bg.png) repeat center center fixed' : 'url(img/hero-bg.png) repeat center center'}`}>
                    {/* <BackgroundContainer height={`${width > 768 ? '500px' : '500px'}`} background="url(img/bg.png) no-repeat center center fixed" /> */}
                    <TopNav background={`rgba(42, 44, 49, ${scrollActive ? '1' : '.3'})`}>
                        <MantisContainer width="100%">
                            <GridContainer>
                                <GridComponent>
                                    {/* <GridContainer gridTemplateColumns="repeat(2, 1fr)" gridGap="0px">
                                        <GridComponent>
                                            <CenterComponent height="60px">
                                                <ButtonContainer>
                                                    <StyledButton background="none" padding="0">Developers</StyledButton>
                                                </ButtonContainer>
                                            </CenterComponent>
                                        </GridComponent>
                                        <GridComponent>
                                            <CenterComponent height="60px">
                                                <ButtonContainer>
                                                    <StyledButton background="none" padding="0">Companies</StyledButton>
                                                </ButtonContainer>
                                            </CenterComponent>
                                        </GridComponent>
                                    </GridContainer> */}
                                </GridComponent>
                                <GridComponent>
                                    <GridContainer gridTemplateColumns="repeat(1, 1fr)" gridGap="0px">
                                        <GridComponent>
                                            <CenterComponent height="60px">
                                                <ButtonContainer>
                                                    <StyledButton onClick={onClickLogo} fontSize="16px" color="#DC6BB6" background="none" padding="0" fontWeight="500">AngelCode</StyledButton>
                                                </ButtonContainer>
                                            </CenterComponent>
                                        </GridComponent>
                                    </GridContainer>
                                </GridComponent>
                                <GridComponent>
                                    <GridContainer gridTemplateColumns="repeat(3, 1fr)" gridGap="0px">
                                        <GridComponent>
                                            <CenterComponent height="60px">
                                                <ButtonContainer>
                                                    <StyledButton background="none" padding="0"></StyledButton>
                                                </ButtonContainer>
                                            </CenterComponent>
                                        </GridComponent>
                                        <GridComponent>
                                            <CenterComponent height="60px">
                                                <ButtonContainer>
                                                    <StyledButton background="none" padding="0"></StyledButton>
                                                </ButtonContainer>
                                            </CenterComponent>
                                        </GridComponent>
                                        <GridComponent>
                                            <CenterComponent height="60px">
                                                <ButtonContainer>
                                                    <StyledButton background="none" padding="0" onClick={onClickContactLink}>Contact</StyledButton>
                                                </ButtonContainer>
                                            </CenterComponent>
                                        </GridComponent>
                                    </GridContainer>
                                </GridComponent>
                            </GridContainer>
                        </MantisContainer>
                    </TopNav>
                    <CenterComponent height={`${width > 768 ? '500px' : '500px'}`} position={`${width > 768 ? 'absolute' : 'relative'}`}>
                        <div>
                            <MantisTitle color="rgba(220, 107, 182, 0.6)" fontSize="40px">AngelCode</MantisTitle>
                            <MantisTitle color="rgba(255, 255, 255, 0.6)" fontSize="20px">A New Era in Startup Funding</MantisTitle>
                        </div>
                    </CenterComponent>
                </MantisSection>
                <MantisSection height={`${width > 768 ? '300px' : '300px'}`} boxShadow background="rgba(232, 232, 233, 1)">
                    <CenterComponent height={`${width > 768 ? '300px' : '300px'}`} >
                        <div>
                            <Description width={`${width > 768 ? '50%' : '80%'}`} fontSize="22px" lineHeight="28px"><InlineText color="rgba(220,107,182,1)">AngelCode</InlineText> brings ideas to life by connecting Startups with Angel Developers through Code for Equity Funding.</Description>
                            <Description fontSize="15px" fontWeight="500">{'<Code for Equity/>'}</Description>
                        </div>
                    </CenterComponent>
                </MantisSection>
                <MantisSection height={`${width > 768 ? '200px' : '150px'}`} background="rgba(186, 140, 171, 1)" boxShadow>
                    <GridContainer gridGap='0' gridTemplateColumns={`${width > 768 ? 'repeat(auto-fit, minmax(300px, 1fr))' : 'repeat(3, 1fr)'}`}>
                        <GridComponent 
                            boxShadow={activeComponent==='AngelCode'} 
                            background={activeComponent==='AngelCode' ? '#292C31' : '' }
                            color={activeComponent==='AngelCode' ? '#fff' : '' }
                            cursor="pointer"
                            height={`${width > 768 ? '200px' : '150px'}`}
                        >
                            <CenterComponent height={`${width > 768 ? '200px' : '150px'}`} onClick={() => onComponentClick('AngelCode')}>
                                <div>
                                    <FontAwesomeIcon icon={faArrowTrendUp} fontSize={'40px'} />
                                    {width > 768  && <MantisTitle padding="20px 0px 0px 0px">Code for Equity</MantisTitle>}
                                </div>
                            </CenterComponent>
                        </GridComponent>
                        <GridComponent 
                            boxShadow={activeComponent==='AppDevelopment'} 
                            background={activeComponent==='AppDevelopment' ? '#292C31' : '' }
                            color={activeComponent==='AppDevelopment' ? '#fff' : '' }
                            cursor="pointer"
                            height={`${width > 768 ? '200px' : '150px'}`}
                        >
                            <CenterComponent height={`${width > 768 ? '200px' : '150px'}`} onClick={() => onComponentClick('AppDevelopment')}>
                                <div>
                                    <FontAwesomeIcon icon={faLightbulb} fontSize={'40px'} />
                                    {width > 768  && <MantisTitle padding="20px 0px 0px 0px">Startups</MantisTitle>}
                                </div>
                            </CenterComponent>
                        </GridComponent>
                        <GridComponent 
                            boxShadow={activeComponent==='DeveloperNetwork'} 
                            background={activeComponent==='DeveloperNetwork' ? '#292C31' : '' }
                            color={activeComponent==='DeveloperNetwork' ? '#fff' : '' }
                            cursor="pointer"
                            height={`${width > 768 ? '200px' : '150px'}`}
                        >
                            <CenterComponent height={`${width > 768 ? '200px' : '150px'}`} onClick={() => onComponentClick('DeveloperNetwork')}>
                                <div>
                                    <FontAwesomeIcon icon={faTerminal} fontSize={'40px'}/>
                                    {width > 768  && <MantisTitle padding="20px 0px 0px 0px">Developers</MantisTitle>}
                                </div>
                            </CenterComponent>
                        </GridComponent>
                    </GridContainer>
                </MantisSection>
                <MantisSection height={`${width > 768 ? '300px' : '400px'}`} boxShadow background="rgba(232, 232, 233, 1)">
                    <CenterComponent height={`${width > 768 ? '300px' : '400px'}`}>
                        {(activeComponent === 'AngelCode') && (
                            <div>
                                <FontAwesomeIcon icon={faArrowTrendUp} fontSize={'40px'} />
                                <MantisTitle>Code for Equity Funding</MantisTitle>
                                <Description width={`${width > 768 ? '50%' : '80%'}`} fontSize={`${width > 768 ? '12px' : '18px'}`}>Code for Equity Funding is the new way to fund Startups by connecting Startups with top tier Angel Developers. Developers will invest their time and skills to build the product in exchange for equity in the company.</Description>
                            </div>
                        )}
                        {(activeComponent === 'AppDevelopment') && (
                            <div>
                                <FontAwesomeIcon icon={faLightbulb} fontSize={'40px'} />
                                <MantisTitle>Startups</MantisTitle>
                                <Description width={`${width > 768 ? '50%' : '80%'}`} fontSize={`${width > 768 ? '12px' : '18px'}`}>
                                    Many Startups fail before they even get started because they are unable to obtain the proper funding to hire the engineers needed to build their vision. <InlineText color="rgba(220,107,182,1)">AngelCode</InlineText> cuts out the middle man and connects you directly with top engineers who are willing to build your idea for equity in your company. 
                                </Description>
                            </div>
                        )}
                        {(activeComponent === 'DeveloperNetwork') && (
                            <div>
                                <FontAwesomeIcon icon={faTerminal} fontSize={'40px'}/>
                                <MantisTitle>Developers</MantisTitle>
                                <Description width={`${width > 768 ? '50%' : '80%'}`} fontSize={`${width > 768 ? '12px' : '18px'}`}>
                                    Looking to take on a project and grow your portfolio? Have you always wanted to get into a company at the ground level but didn’t have the funds to invest? With <InlineText color="rgba(220,107,182,1)">AngelCode</InlineText>, you can now leverage your skills to invest in Startups from the very beginning.
                                </Description>
                            </div>
                        )}
                    </CenterComponent>
                </MantisSection>
                <MantisSection height={`${width > 768 ? '500px' : '800px'}`} background="rgba(56, 70, 86, 1)">
                    <Developers />
                </MantisSection>
                <MantisSection height={`${width > 768 ? '200px' : '200px'}`}>
                    <GridContainer gridTemplateColumns="repeat(1, 1fr)" gridGap="0px">
                        <GridComponent>
                            <CenterComponent height={`${width > 768 ? '200px' : '200px'}`}>
                                <div>
                                    <MantisTitle padding="20px 0px 0px 0px" fontWeight="500" color="#292C31">Why AngelCode?</MantisTitle>
                                    <Description color="#292C31" width={`${width > 768 ? '50%' : '80%'}`} fontSize={`${width > 768 ? '12px' : '18px'}`}><InlineText color="rgba(220,107,182,1)">AngelCode</InlineText> pre-screens all of the engineers in the Angel Developer Network to ensure you have acces to top tier developers.</Description>
                                </div>
                            </CenterComponent>
                        </GridComponent>
                    </GridContainer>
                </MantisSection>
                <MantisSection height={`${width > 768 ? '300px' : '500px'}`} background="rgba(232,232,233,1)">
                    <GridContainer gridTemplateColumns="repeat(1, 1fr)" gridGap="0px">
                        <GridComponent>
                            <CenterComponent height={`${width > 768 ? '300px' : '500px'}`}>
                                <div>
                                    <MantisTitle padding="20px 0px 0px 0px" color="#292C31" fontWeight="500"><InlineText color="rgba(56,70,86,1)" fontWeight="500">AngelCore Full Service</InlineText></MantisTitle>
                                    <Description color="#292C31" fontStyle="italic" width={`${width > 768 ? '50%' : '80%'}`} fontSize={`${width > 768 ? '12px' : '16px'}`}>Don't know where to get started or need more help getting your idea off the ground?</Description>
                                    <Description color="#292C31" width={`${width > 768 ? '50%' : '80%'}`} fontSize={`${width > 768 ? '12px' : '16px'}`}>The <InlineText color="rgba(220,107,182,1)">AngelCode</InlineText> team has over 15 years of experience building custom platforms from the ground up across all industries.</Description>
                                    <Description color="#292C31" width={`${width > 768 ? '50%' : '80%'}`} fontSize={`${width > 768 ? '12px' : '16px'}`}>With our full service solution, <InlineText color="rgba(56,70,86,1)" fontWeight="500">AngelCore</InlineText>, we can help with everything from contract signing, project scoping & management, the hiring process, application architecture & development, and preparing you for investor pitches.</Description>
                                </div>
                            </CenterComponent>
                        </GridComponent>
                    </GridContainer>
                </MantisSection>
                <MantisSection height={`${width > 768 ? '300px' : '600px'}`} background="rgba(56, 70, 86, 1)" boxShadow>
                    <Startup />
                </MantisSection>
                <MantisSection height={`${width > 768 ? '500px' : '600px'}`} boxShadow>
                    <Contact />
                </MantisSection>
                <MantisSection height={`${width > 768 ? '120px' : '120px'}`}>
                    <Footer />
                </MantisSection>
            </MainContainer>
           
        </AppBackground>
    )
}

export default AngelCode