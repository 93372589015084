import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
// import { StyledTable, StyledTHead, StyledTBody } from "../Styled"

const MantisTableHead = ({keys}) => {
    return (
        <TableHead>
            <TableRow>
                {keys.map((key) => {
                    return (<TableCell key={key}>{key}</TableCell>)
                })}
            </TableRow>
        </TableHead>
    )
}

const MantisTableBody = ({data}) => {
    return (
        <TableBody>
            {data.map((obj, index) => {
                return (<TableRow key={index}>
                    {Object.keys(data[0]).map((key) => {
                        return (<TableCell>{(obj[key] || {}).name || (obj[key] || {}).email_address || (obj[key] || '')}</TableCell>)
                    })}
                </TableRow>)
            })}
        </TableBody>
    )
}


const MantisTable = (data) => {
    return (
        <TableContainer component={Paper}>
            <Table>
                <MantisTableHead keys={Object.keys(data.data.data[0])} />
                <MantisTableBody data={data.data.data} />
            </Table>
        </TableContainer>
    )
}

export default MantisTable