import { BrowserRouter } from 'react-router-dom'
import { ToastProvider } from 'react-toast-notifications'
import Routing from './routing'

import { QueryClient, QueryClientProvider } from 'react-query'
import MantisProvider from './context/MantisProvider'

const queryClient = new QueryClient()


const App = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <MantisProvider>
        <ToastProvider placement={'bottom-center'} autoDismiss={true}>
          <BrowserRouter>
              <Routing />
          </BrowserRouter>
        </ToastProvider>
      </MantisProvider>
    </QueryClientProvider>
  );
}

export default App;
