import styled from 'styled-components'


// TODO: Create Theme Provider
const $TopNavBackground = '#294257'
const $SideNavBackground = '#292C31'
const $ButtonColor = '#294257';


export const AppBackground = styled.div`
    background: #22272B;
    height: 100vh;
    font-family: 'Avenir Next';

    & input, textarea, button {
        font-family: 'Avenir Next';
    }
` 

export const TopNav = styled.div`
    width: 100%;
    overflow-x: hidden;
    height: 60px;
    background: ${props => props.background || $TopNavBackground};
    z-index: 1;
    position: ${props => props.position || 'fixed'};
    // @media only screen and (max-width: 768px) {
    //     display: none;
    // }
`

export const TopNavLeftContainer = styled.div`
    float: left;
`

export const TopNavRightContainer = styled.div`
    float: right;
`

export const InlineText = styled.span`
    display: inline-block;
    color: ${props => props.color || '#294257'};
    font-size: ${props => props.fontSize || ''};
    padding: ${props => props.padding || '0'};
    font-weight: ${props => props.fontWeight || 'normal'};
`

export const SideNav = styled.div`
    position: relative;
    left: 0;
    width: 80px;
    z-index: 1;
    background: ${$SideNavBackground};
    height: calc(100vh - 60px);
    top: ${props => props.top || '60px'};
    // font-weight: ${props => props.fontWeight || 'bold'};
`

export const MainContainer = styled.div`
    height: ${props => props.height || 'calc(100vh - 60px)'};
    position: absolute;
    top: ${props => props.top || '60px'};
    left: ${props => props.left || '80px'};
    width: ${props => props.width || 'calc(100vw - 80px)'};
    overflow-y: scroll;
    overflow-x: hidden;
    background: ${props => props.background || ''};
`

export const BackgroundContainer = styled.div`
    height: ${props => props.height || 'inherit'};
    background: ${props => props.background || '#fff'};
    width: ${props => props.width || '100%'};
    background-size: 100% 100%;
    filter: ${props => props.filter || 'blur(3px)'};
    transform: ${props => props.transform || ''};
    background-size: cover;

    position: absolute;
    content: " ";
    top: ${props => props.top || '0'};
    left: 0;
    z-index: 0;
`

export const Description = styled.div`
    font-size: ${props => props.fontSize || '12px'};
    font-weight: ${props => props.fontWeight || 'normal'};
    padding: ${props => props.padding || '4px 0px'};
    color: ${props => props.color || '#294257'};
    width: ${props => props.width || '100%'};
    margin: ${props => props.margin || 'auto'};
    line-height: ${props => props.lineHeight || 'inherit'};
    font-style: ${props => props.fontStyle || 'normal'};
`

export const MantisContainer = styled.div`
    width: ${props => props.width || '100%'};
    margin: ${props => props.margin || 'auto'};
    height: ${props => props.height || 'inherit'};
`

export const MantisSection = styled.div`
    height: ${props => props.height || '100vh'};
    background: ${props => props.background || '#fff'};
    background-size: cover;
    width: ${props => props.width || '100%'};
    box-shadow: ${props => props.boxShadow ? '0 .5rem 1rem rgba(0,0,0,.15)' : 'none'};
    position: relative;
    border: ${props => props.border || 'none'};
`

export const StyledHR = styled.div`
    border: ${props => props.border || 'solid .5px #87929b'};
`

export const SignInContainer = styled.div`
    margin: auto;
    padding-top: 200px;
`

export const ImageContainer = styled.div`
    width: 100%;
`

export const StyledImage = styled.img`
    width: ${props => props.width || 'auto'};
    height: ${props => props.height || '100vh'};
    object-fit: ${props => props.objectFit || 'cover'};
`


export const InputContainer = styled.div`
    width: ${props => props.width || '50%'};
    margin: ${props => props.margin || '12px auto'};
    display: flex;
    position: relative;
    & input {
        width: 100%;
    }
`

export const ButtonContainer = styled.div`
    width: 100%;
    margin: 12px auto;
    display: flex;
    text-align: center;
`

export const StyledInput = styled.input`
    border: ${props => props.border || 'none'};
    border-radius: ${props => props.borderRadius || '4px'};
    padding: ${props => props.padding || '20px'};
    font-size: ${props => props.fontSize || '16px'};
    background: ${props => props.background || 'none'};
    color: ${props => props.color || '#294257'};

    &::placeholder {
        ${props => props.color || '#294257'};
        opacity: 1;
    }
`

export const TextAreaContainer = styled.div`
    width: ${props => props.width || '50%'};
    margin: ${props => props.margin || '12px auto'};
    display: flex;
    position: relative;
    & textarea {
        width: 100%;
    }
`

export const StyledTextArea = styled.textarea`
    border: ${props => props.border || 'none'};
    border-radius: ${props => props.borderRadius || '4px'};
    padding: ${props => props.padding || '20px'};
    font-size: ${props => props.fontSize || '16px'};
    background: ${props => props.background || 'none'};
    color: ${props => props.color || '#294257'};
    &::placeholder {
        ${props => props.color || '#294257'};
        opacity: 1;
    }
`

export const StyledButton = styled.button`
    cursor: pointer;
    padding: ${props => props.padding || '12px 36px'};
    font-size: ${props => props.fontSize || '12px'};
    margin: auto;
    border: none;
    position: ${props => props.position || 'relative'};
    right: ${props => props.right || ''};
    height: ${props => props.height || ''};
    width: ${props => props.width || ''};
    color: ${props => props.color || '#DEDAD7'};
    background: ${props => props.background || $ButtonColor};
    font-weight: ${props => props.fontWeight || '500'};
    border-radius: ${props => props.borderRadius || '4px'};
`

export const SignUpContainer = styled.div`
    text-align: ${props => props.textAlign || 'left'};
`

export const MantisStyledCardContainer = styled.div`
    display: grid;
    grid-template-columns: ${props => props.gridTemplateColumns || `repeat(auto-fit, minmax(, 1fr))`}; 
    grid-gap: 12px;
    margin: 12px;
`

export const MantisTitle = styled.div`
    font-size: ${props => props.fontSize || '20px'};
    font-weight: ${props => props.fontWeight || 'normal'};
    padding: ${props => props.padding || '12px 0px'};
    color: ${props => props.color || ''};
`


export const GridContainer = styled.div`
    display: grid;
    grid-template-columns: ${props => props.gridTemplateColumns || 'repeat(auto-fit, minmax(300px, 1fr))'}; 
    grid-gap: ${props => props.gridGap || '12px'};
    height: ${props => props.height || '300px'};
`

export const GridComponent = styled.div`
    background: ${props => props.background || ''};
    box-shadow: ${props => props.boxShadow ? '0 .5rem 1rem rgba(0,0,0,.15)' : 'none'};
    color: ${props => props.color || '#292C31'};
    position: relative;
    cursor: ${props => props.cursor || 'auto'};
    background-size: cover;
`

export const CenterComponent = styled.div`
    display: table;
    height: ${props => props.height || '300px'};
    width: ${props => props.width || '100%'};
    position: ${props => props.position || ''};
    

    & > div {
        display: table-cell;
        vertical-align: ${props => props.verticalAlign || 'middle'};
        text-align: ${props => props.textAlign || 'center'};
        height: inherit;
        width: ${props => props.width || '100%'};
    }
`

export const MantisStyledCard = styled.div`
    background: #C6C6C6;
    box-shadow: 0 .5rem 1rem rgba(0,0,0,.15);
    height: 280px;
    width: 100%;
    border-radius: 4px;
    max-width: 320px;
`

export const StyledAnchorContainer = styled.div`
    padding: 8px 4px;
    position: ${props => props.position || 'relative'};
    bottom: ${props => props.bottom || ''};
`

export const StyledAnchor = styled.a`
    text-decoration: none;
    color: #DEDAD7;
    font-size: 12px;
    padding: 12px 8px;
    &: hover {
        color: #294257;
    }

    &: hover {
        color: #294257;
    }
    
`

export const FlexContainer = styled.div`
    display: ${props => props.display || 'inline-grid'};
    width: ${props => props.width || '50%'};
    vertical-align: ${props => props.verticalAlign || 'top'};
    margin: ${props => props.margin || '0'};

`

export const FlexContainerTitle = styled.div`
    font-size: ${props => props.fontSize || '24px'};
    font-weight: ${props => props.fontWeight || 'bold'};
    padding: ${props => props.padding || '24px 0px'};
    text-align: ${props => props.textAlign || 'center'};
    background: ${props => props.background || '#294257'};
    color: ${props => props.color || '#fff'};
`

export const ComponentTitle = styled.div`
    font-size: ${props => props.fontSize || '12px'};
    font-weight: ${props => props.fontWeight || 'bold'};
    padding: ${props => props.padding || '12px 0px'};
    text-align: ${props => props.textAlign || ''};
    background: ${props => props.background || ''};
    color: ${props => props.color || ''};
`

export const FlexComponent = styled.div`
    margin: ${props => props.margin || '12px'};
    height: ${props => props.height || 'calc(100vh - 106px)'};
    background: ${props => props.background || 'none'};
    overflow-y: hidden;
`

export const ScrollableContainer = styled.div`
    height: ${props => props.height || '80px'};
    overflow-y: scroll;
    padding: 12px;
`


