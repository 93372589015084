import styled from 'styled-components'


// type InterfaceProps = {
//     fontWeight?: string
//     height?: string
// }

export const Container = styled.div`
    height: 100vh;
`

export const Title = styled.div`
    font-weight: bold;
    // font-weight: ${props => props.fontWeight || 'bold'};
`

export const StyledTable = styled.table`
    font-size: 12px;
    margin: 20px;
    border: solid;
`

export const StyledTHead = styled.thead`
    font-size: 12px;
`

export const StyledTBody = styled.tbody`
    font-size: 12px;
`
