import { useMutation } from 'react-query'
import ApiService from '../../services/ApiService'


export const useAngelCodeMutation = () => {
    

    const { error, isLoading, mutateAsync } = useMutation((payload) => {
        const operation = payload.operation || 'get'
        const endpoint = `/${payload.endpoint || 'accounts'}`

        delete payload.operation

        if (operation === 'delete') {
            return ApiService.delete(endpoint, payload);
        } 
        if (operation === 'post') {
            return ApiService.post(endpoint, payload.data);
        } 
        if (operation === 'put') {
            const updatePayload = payload.data
            delete updatePayload.created_date
            delete updatePayload.modified_date
            return ApiService.put(endpoint, updatePayload);
        } 
        return ApiService.get(endpoint)
    })
    return {
        isError: error,
        angelCodeAPI: mutateAsync,
        isLoading
    }
}


