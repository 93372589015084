import { Suspense, useContext, useState } from 'react'
import { Routes, Route} from 'react-router-dom'
import { Container } from './components/Styled'

import { MantisContext } from './context/MantisContext'
import { QuantumMantis } from './pages/QuantumMantis'
import { MantisSignIn } from './components/MantisSignIn'
import { MantisDashboard } from './pages/MantisDashboard'
import { MantisEndpoints } from './pages/MantisEndpoints'
import { AngelCode } from './pages/AngelCode'

import { MantisSignOut } from './components/MantisSignOut'
import { AppBackground, TopNav, SideNav, MainContainer, TopNavLeftContainer, TopNavRightContainer, FlexContainer, FlexComponent, ButtonContainer, StyledButton, StyledAnchorContainer, StyledAnchor } from "./components/Styled/mantis"



const QuantumMantisApps = ({resp, config, session, setSession}) => {
  const [endpointData, setEndpointData] = useState(resp.data)

  if (!session.active) return <AppBackground><MantisSignIn session={session} setSession={setSession} /></AppBackground>

  return (
    <AppBackground>
      <TopNav>
          <TopNavLeftContainer>
              <ButtonContainer>
                <StyledButton><StyledAnchor href="/">Project Mantis</StyledAnchor></StyledButton>
              </ButtonContainer>
          </TopNavLeftContainer>
          <TopNavRightContainer>
              <MantisSignOut setSession={setSession}/>
          </TopNavRightContainer>
      </TopNav>
      <SideNav top="60px">
          {endpointData.map((config) => {
              if (config.key === 'resource') {
                return (
                    <StyledAnchorContainer key={config.id}>
                      <StyledAnchor href={`${config.endpoint}`}>{config.name}</StyledAnchor>
                    </StyledAnchorContainer>
                )
            }
            return ''
          })}
          <StyledAnchorContainer position="absolute" bottom="40px">
            <StyledAnchor href={`/quantum`}>Quantum</StyledAnchor>
          </StyledAnchorContainer>
          <StyledAnchorContainer position="absolute" bottom="8px">
            <StyledAnchor href={`/mantis`}>Mantis</StyledAnchor>
          </StyledAnchorContainer>
      </SideNav>
      <MainContainer>
        <FlexContainer width="100%">
            <FlexComponent height="unset">
              <QuantumMantis config={config} />
            </FlexComponent>
        </FlexContainer>
      </MainContainer>
    </AppBackground>
  )
}

const QuantumMantisApp = ({resp, session, setSession}) => {
  const [endpointData, setEndpointData] = useState(resp.data)

  if (!session.active) return <AppBackground><MantisSignIn session={session} setSession={setSession} /></AppBackground>

  return (
    <AppBackground>
      <TopNav>
          <TopNavLeftContainer>
              <ButtonContainer>
                  <StyledButton><StyledAnchor href="/">Project Mantis</StyledAnchor></StyledButton>
              </ButtonContainer>
          </TopNavLeftContainer>
          <TopNavRightContainer>
              <MantisSignOut setSession={setSession}/>
          </TopNavRightContainer>
      </TopNav>
      <SideNav top="60px">
          {endpointData.map((config) => {
              if (config.key === 'resource') {
                  return (
                      <StyledAnchorContainer>
                        <StyledAnchor href={`${config.endpoint}`}>{config.name}</StyledAnchor>
                      </StyledAnchorContainer>
                  )
              }
              return ''
          })}
          <StyledAnchorContainer position="absolute" bottom="40px">
            <StyledAnchor href={`/quantum`}>Quantum</StyledAnchor>
          </StyledAnchorContainer>
          <StyledAnchorContainer position="absolute" bottom="8px">
            <StyledAnchor href={`/mantis`}>Mantis</StyledAnchor>
          </StyledAnchorContainer>
      </SideNav>
      <MainContainer></MainContainer>
    </AppBackground>
  )
}

const Routing = () => {
  const [session, setSession] = useState({active: false})

  const { data, isLoading } = useContext(MantisContext)

  if (isLoading) {
      return (<div />)
  }

  const resp = data.data

  return (
    <Suspense>
        <Routes>
          <Route path="/quantum" element={<QuantumMantisApp resp={resp} session={session} setSession={setSession} />} />
          {resp.code === 201 &&
            resp.data.map(config => {
              if (config.key === 'resource') {
                return (<Route key={config.id} path={config.endpoint} element={<QuantumMantisApps resp={resp} config={config} session={session} setSession={setSession} />} />)
              }
              return ''
            })
          }
          
          <Route path="/mantis" element={<MantisDashboard session={session} setSession={setSession} />} />
          <Route path="/mantis/endpoints" element={<MantisEndpoints resp={resp} session={session} setSession={setSession} />} />

          <Route path="/" element={<AngelCode resp={resp} setSession={setSession} />} />
        </Routes> 
    </Suspense>
  );
}

export default Routing;