import { useState } from 'react'
import { useToasts } from 'react-toast-notifications'
import { useMantisSignInMutation } from '../../hooks/mantis/mantis.hooks'
import { MantisSignUp } from '../MantisSignUp'

import { SignInContainer, InputContainer, ButtonContainer, StyledInput, StyledButton } from "../Styled/mantis"


const MantisSignIn = ({ session, setSession }) => {

    const { addToast } = useToasts()
    const { mantisSignIn } = useMantisSignInMutation()
    

    const [payload, setPayload] = useState({})
    const [emailAddress, setEmailAddress] = useState('')
    const [password, setPassword] = useState('')
    

    const onClickSignIn = async() => {
        let warning = false;

        if (!emailAddress) {
            warning = true
            addToast('Email Address Empty', { appearance: 'warning' })
        }

        if (!password) {
            warning = true
            addToast('Password Empty', { appearance: 'warning' })
        }

        // TODO: Password & Email Validation Logic
        if (!emailAddress.includes('.') || !emailAddress.includes('@')) {
            warning = true
            addToast('Invalid Email Address', { appearance: 'warning' })
        }

        if (password.length < 8) {
            warning = true
            addToast('Password too short', { appearance: 'warning' })
        }

        if (warning) return;

        // TODO: Send payload to API
        const resp = await mantisSignIn(payload)
        if (resp.data.code === 200) {
            // Reset Payload
            setPayload({})
            setEmailAddress('')
            setPassword('')

            addToast('Successfully Signed In', { appearance: 'success' })
            setSession(session => ({...session, active: true}))
        }  else {
            addToast('Sign In Failed', { appearance: 'error' })
        }
    }

    const onBlurSignInForm = (key, event) => {
        setPayload(payload => ({...payload, [key]: event.target.value}))
    }
    

    const onChangeEmail = (event) => {
        event.target.value = event.target.value.toLowerCase()
        setEmailAddress(event.target.value)
    }

    const onChangePassword = (event) => {
        setPassword(event.target.value)
    }
    

    return (
        <SignInContainer>
            <InputContainer>
                <StyledInput 
                    type="email" 
                    placeholder="Email Address" 
                    onBlur={(e) => onBlurSignInForm('email_address', e)}
                    onChange={(e) => onChangeEmail(e)}
                    value={emailAddress}
                />
            </InputContainer>
            <InputContainer>
                <StyledInput 
                    type="password" 
                    placeholder="Password" 
                    onBlur={(e) => onBlurSignInForm('password', e)}
                    onChange={(e) => onChangePassword(e)}
                    value={password}
                />
            </InputContainer>
            <ButtonContainer>
                <StyledButton onClick={onClickSignIn}>Login</StyledButton>
            </ButtonContainer>
            {/* <MantisSignUp textAlign={'center'} /> */}
        </SignInContainer>
    )
}

export default MantisSignIn