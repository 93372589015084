import { MantisStyledCard } from "../Styled/mantis"


const MantisCard = ({children, ...props}) => {

    return (
        <MantisStyledCard>
            {children}
        </MantisStyledCard>
    )
}

export default MantisCard