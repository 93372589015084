import { useQuery, useMutation } from 'react-query'
import ApiService from '../../services/ApiService'

export const useMantisConfig = () => {
    const endpoint = '/mantis/config'
    const { isLoading, isError, data, refetch } = useQuery(`mantis-config`, () => ApiService.get(endpoint))
    return {
        isLoading,
        isError,
        data,
        refetch
    }
}

export const useMantis = (config) => {
    const endpoint = `/${config.endpoint}`
    const { isLoading, isError, data, refetch } = useQuery(config.endpoint, () => ApiService.get(endpoint))
    return {
        isLoading,
        isError,
        data,
        refetch
    }
}


export const useMantisSignInMutation = () => {
    const endpoint = `/mantis/signin`
    const { error, isLoading, mutateAsync } = useMutation((payload) => {
        return ApiService.post(endpoint, payload);
    })
    return {
        isError: error,
        mantisSignIn: mutateAsync,
        isLoading
    }
}

export const useMantisEndpointsMutation = () => {
    const endpoint = `/mantis/config`

    const { error, isLoading, mutateAsync } = useMutation((payload) => {
        const operation = payload.operation || 'get'
        delete payload.operation

        if (operation === 'delete') {
            console.log(payload)
            return ApiService.delete(endpoint, payload);
        } 
        if (operation === 'post') {
            return ApiService.post(endpoint, {...payload.data, key: 'resource'});
        } 
        if (operation === 'put') {
            const updatePayload = payload.data
            delete updatePayload.created_date
            delete updatePayload.modified_date
            return ApiService.put(endpoint, updatePayload);
        } 
        return ApiService.get(endpoint)
    })
    return {
        isError: error,
        mantisEndpoints: mutateAsync,
        isLoading
    }
}

export const useMantisSchemaMutation = () => {
    

    const { error, isLoading, mutateAsync } = useMutation((payload) => {
        const endpoint = `/${payload.endpoint}/schema`

        const operation = payload.operation || 'get'
        delete payload.operation

        if (operation === 'delete') {
            return ApiService.delete(endpoint, payload);
        } 
        if (operation === 'post') {
            return ApiService.post(endpoint, {...payload.data});
        } 
        if (operation === 'put') {
            const updatePayload = payload.data
            delete updatePayload.created_date
            delete updatePayload.modified_date
            return ApiService.put(endpoint, updatePayload);
        } 
        return ApiService.get(endpoint)
    })
    return {
        isError: error,
        mantisSchema: mutateAsync,
        isLoading
    }
}

