import axios from 'axios'

export const axiosClient = axios.create({
    baseURL: 'https://api.angelcode.co',
    // baseURL: 'http://localhost:8080',
    headers: {
        'Content-type': 'application/json',
        'Authorization': `Bearer ${'e38372cd5b1f32eba8dfb3a26112a9a4'}`
    }
})

const ApiService = {
    get: async (endpoint) => {
        const resp = await axiosClient.get(`${endpoint}`)
        return resp
    },
    post: async (endpoint, payload) => {
        const resp = await axiosClient.post(endpoint, payload)
        return resp
    },
    put: async (endpoint, payload) => {
        const resp = await axiosClient.put(endpoint, payload)
        return resp
    },
    delete: async (endpoint, payload) => {
        console.log(payload)
        const resp = await axiosClient.delete(endpoint, payload)
        return resp
    }
}

export default ApiService