import { useToasts } from 'react-toast-notifications'

import { ButtonContainer, StyledButton } from "../Styled/mantis"


const MantisSignOut = ({ session, setSession }) => {

    const { addToast } = useToasts()

    const onClickSignOut = async() => {
        addToast('Successfully Signed Out', { appearance: 'success' })
        setSession({active: false})
    }

    return (
        <div>
            <ButtonContainer>
                <StyledButton onClick={onClickSignOut}>Sign Out</StyledButton>
            </ButtonContainer>
        </div>
    )
}

export default MantisSignOut