import { useState } from 'react'
import { useMantisEndpointsMutation } from '../../hooks/mantis/mantis.hooks'
import { useMantisSchemaMutation } from '../../hooks/mantis/mantis.hooks'
import { MantisSignIn } from '../../components/MantisSignIn'
import { MantisSignOut } from '../../components/MantisSignOut'
import { AppBackground, TopNav, SideNav, MainContainer, TopNavLeftContainer, TopNavRightContainer, 
    FlexContainer, FlexContainerTitle, FlexComponent, ButtonContainer, StyledButton, InputContainer, 
    StyledInput, StyledAnchorContainer, StyledAnchor, ComponentTitle, ScrollableContainer } from "../../components/Styled/mantis"

const MantisEndpoints = ({ resp, session, setSession }) => {
    const { mantisEndpoints } = useMantisEndpointsMutation()
    const { mantisSchema } = useMantisSchemaMutation()

    const [endpointData, setEndpointData] = useState(resp.data)
    const [schemas, setSchema] = useState(null)
    const [mantisDefaultSchema, setMantisDefaultSchema] = useState(resp.data.filter(config => {return config.key === 'schema'})[0])

    const [activeConfig, setActiveConfig] = useState(null)
    const [activeSchema, setActiveSchema] = useState(null)
    

    const onBlurNewEndpoint = async (event) => {
        if (event.target.value) {
            await mantisEndpoints({operation: 'post', data: {endpoint: event.target.value}})

            // TODO: Return the updated payload in the response from api
            const resp = await mantisEndpoints({})
            setEndpointData(resp.data.data)
        }
        event.target.value = ''
       
    }

    const deleteEvent = async (id) => {
        await mantisEndpoints({operation: 'delete', data: {id: id}})
        // TODO: Return the updated payload in the response from api
        const resp = await mantisEndpoints({})
        setEndpointData(resp.data.data)
    }

    const updateConfigEvent = async () => {
        await mantisEndpoints({operation: 'put', data: activeConfig})
        setEndpointData(endpointData.map((config) => {if (config.id === activeConfig.id) {return activeConfig} else {return config}}))
    }

    const editEndpoint = async (config) => {
        setActiveConfig(config)
        const resp = await mantisSchema({endpoint: config.endpoint})
        setSchema(resp.data.data)
    }

    const editSchema = async (schema) => {
        setActiveSchema(schema)
    }

    const updateSchemaEvent = async (endpoint) => {
        await mantisSchema({operation: 'put', endpoint: endpoint, data: activeSchema})
        setSchema(schemas.map((schema) => {if (schema.id === activeSchema.id) {return activeSchema} else {return schema}}))
    }

    if (!session.active) return <AppBackground><MantisSignIn session={session} setSession={setSession} /></AppBackground>
    
    // TODO: Pull schema from config and replace
    return (
        <AppBackground>
            <TopNav>
                <TopNavLeftContainer>
                    <ButtonContainer>
                        <StyledButton><StyledAnchor href="/">Project Mantis</StyledAnchor></StyledButton>
                    </ButtonContainer>
                </TopNavLeftContainer>
                <TopNavRightContainer>
                    <MantisSignOut setSession={setSession}/>
                </TopNavRightContainer>
            </TopNav>
            <SideNav top="60px">
                <StyledAnchorContainer>
                    <StyledAnchor href={`/mantis/endpoints`}>Endpoints</StyledAnchor>
                </StyledAnchorContainer>
                <StyledAnchorContainer position="absolute" bottom="40px">
                    <StyledAnchor href={`/quantum`}>Quantum</StyledAnchor>
                </StyledAnchorContainer>
                <StyledAnchorContainer position="absolute" bottom="8px">
                    <StyledAnchor href={`/mantis`}>Mantis</StyledAnchor>
                </StyledAnchorContainer>
            </SideNav>
            <MainContainer>
                <FlexContainer>
                    <FlexComponent>
                        {endpointData.map((config) => {
                            if (config.key === 'resource') {
                                return (
                                        <InputContainer key={config.id} margin="12px 0px" width="100%">
                                            <StyledInput 
                                                type="text" 
                                                placeholder="endpoint" 
                                                defaultValue={config.endpoint}
                                                readOnly={false}
                                                color="#fff"
                                                onChange={()=>{}}
                                            />
                                            <StyledButton 
                                                onClick={() => deleteEvent(config.id)}
                                                padding={'12px'}
                                                background={'#df3a50'}
                                                position={'absolute'}
                                                right={'0'}
                                                height={'100%'}
                                                width={'60px'}
                                                borderRadius={'0px 4px 4px 0px'}
                                            >Delete</StyledButton>
                                            <StyledButton 
                                                onClick={() => editEndpoint(config)}
                                                padding={'12px'}
                                                background={'#2A2C31'}
                                                position={'absolute'}
                                                right={'60px'}
                                                height={'100%'}
                                                width={'60px'}
                                                borderRadius={'0px 0px 0px 0px'}
                                            >Edit</StyledButton>
                                        </InputContainer>
                                )
                            }
                            return ''
                        })}
                        <InputContainer  margin="12px 0px" width="100%">
                            <StyledInput 
                                type="text" 
                                placeholder="new endpoint"
                                color="#fff"
                                onBlur={(event)=>{onBlurNewEndpoint(event)}}
                            />
                        </InputContainer>
                    </FlexComponent>
                </FlexContainer>
                <FlexContainer margin="12px 0px">
                    <FlexComponent background="#DEDAD7">
                        <FlexContainer width="100%">
                            {activeConfig && 
                            <div>
                                <FlexContainerTitle>Settings</FlexContainerTitle>
                                <ScrollableContainer>
                                    <div>
                                        <InputContainer key={activeConfig.id} margin="12px auto" width="90%">
                                            <StyledInput 
                                                type="text" 
                                                placeholder="name" 
                                                defaultValue={activeConfig.name}
                                                onChange={()=>{}}
                                                color="#fff"
                                                onBlur={(event) => {setActiveConfig(activeConfig => ({...activeConfig, name: event.target.value}))}}
                                            />
                                            <StyledButton 
                                                onClick={() => updateConfigEvent()}
                                                padding={'12px'}
                                                background={'#4D988B'}
                                                position={'absolute'}
                                                right={'0'}
                                                height={'100%'}
                                                width={'60px'}
                                                borderRadius={'0px 4px 4px 0px'}
                                            >Save</StyledButton>
                                        </InputContainer>
                                        
                                    </div>
                                </ScrollableContainer>
                            </div>}
                            
                        </FlexContainer>
                        <FlexContainer margin="12px 0px" width="100%">
                            {schemas && <div>
                                <FlexContainerTitle>Schema</FlexContainerTitle>
                                <ScrollableContainer height="55vh">
                                {schemas.map((schema) => {
                                    return (
                                        <div key={schema.id}>
                                            <div>
                                                <ComponentTitle>{schema.name || schema.key}</ComponentTitle>
                                                <StyledButton 
                                                    onClick={() => editSchema(schema)}
                                                    padding={'12px'}
                                                    width={'60px'}
                                                    borderRadius={'4px'}
                                                >Edit</StyledButton>
                                            </div>
                                            {activeSchema && activeSchema.id === schema.id && <div>
                                                {mantisDefaultSchema.config.map((config) => {
                                                    return (
                                                        <InputContainer margin="12px auto" width="90%">
                                                            <StyledInput 
                                                                type="text" 
                                                                placeholder={config.key} 
                                                                defaultValue={schema[config.key]}
                                                                onChange={()=>{}}
                                                                onBlur={(event) => {setActiveSchema(activeSchema => ({...activeSchema, key: event.target.value}))}}
                                                            />
                                                            <StyledButton 
                                                                onClick={() => updateSchemaEvent(activeConfig.endpoint)}
                                                                padding={'12px'}
                                                                background={'#4D988B'}
                                                                position={'absolute'}
                                                                right={'0'}
                                                                height={'100%'}
                                                                width={'60px'}
                                                                borderRadius={'0px 4px 4px 0px'}
                                                            >Save</StyledButton>
                                                        </InputContainer>
                                                    )
                                                })}                             
                                            </div>}
                                        </div>
                                    )
                                })}
                                </ScrollableContainer>
                            </div>}
                        </FlexContainer>
                    </FlexComponent>
                </FlexContainer>
            </MainContainer>
        </AppBackground>
    )
}

export default MantisEndpoints